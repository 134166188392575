<template>
  <div class="Part">
    <div class='input'>
      <el-row>
        <el-button type="primary" @click="addCarButton" v-if="authe.Hotel_RegionalDivision_Vehicle_AddVehicle" size="mini">添加车辆</el-button>&nbsp;
          <el-dialog :title="this.title" :visible.sync="addCar" @close="cleanDialogAdd" :close-on-click-modal="false" width="800px">
            <el-form :model="addVehicle" ref="roomForm" :rules="rules">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆用途:" label-width="75px">
                    <el-input v-model="addVehicle.svrVehicleUse" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="车辆类型:" label-width="75px">
                    <el-input v-model="addVehicle.svrVehicleType" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="下次年审:" label-width="75px">
                    <el-date-picker
                            v-model="addVehicle.svrAnnualReview"
                            type="date"
                            size="mini"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width:100%"
                            placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item prop="svrVehicleNumber" label="车牌号码:" label-width="75px">
                    <el-input v-model="addVehicle.svrVehicleNumber" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrTotalMileage" label="总里程:" label-width="75px">
                    <el-input v-model.number="addVehicle.svrTotalMileage" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="下次保养:" label-width="75px">
                    <el-date-picker
                            v-model="addVehicle.svrMaintain"
                            type="date"
                            size="mini"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width:100%"
                            placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="使用性质:" label-width="75px">
                    <el-input v-model="addVehicle.svrCatalog" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="车架号:" label-width="75px">
                    <el-input v-model="addVehicle.svrFrameNumber" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="注册时间:" label-width="75px">
                    <el-date-picker
                            v-model="addVehicle.svrRegistrationTime"
                            type="date"
                            size="mini"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width:100%"
                            placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆油量:" label-width="75px">
                    <el-input v-model="addVehicle.svrCarOil" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="车辆电量:" label-width="75px">
                    <el-input v-model="addVehicle.svrCarBattery" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="车险到期:" label-width="75px">
                    <el-date-picker
                            v-model="addVehicle.svrInsurancePeriod"
                            type="date"
                            size="mini"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width:100%"
                            placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="品牌型号:" label-width="75px">
                    <el-input v-model="addVehicle.svrBrandModel" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrNuclearLoading" label="核载人数:" label-width="75px">
                    <el-input type="number" v-model.number="addVehicle.svrNuclearLoading" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="负责人:" label-width="75px">
                    <el-select v-model="addVehicle.userCoding" placeholder="请选择" size="mini">
                      <el-option
                              v-for="item in userData"
                              :key="item.userCoding"
                              :label="item.suStaffName"
                              :value="item.userCoding">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="16">
                  <el-form-item label="注册地址:" label-width="75px">
                    <el-input v-model="addVehicle.svrRegisteredAddress" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrEngineNumber" label="发动机号:" label-width="75px">
                    <el-input v-model="addVehicle.svrEngineNumber" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="保险公司:" label-width="75px">
                    <el-input v-model="addVehicle.svrInsuranceCompany" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保单号:" label-width="75px">
                    <el-input v-model="addVehicle.svrPolicyNumber" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆照片:" label-width="75px">
                    <el-upload
                            :action="uploadQiniuUrl"
                            :data="picData"
                            ref="upload"
                            list-type="picture-card"
                            :before-upload="beforeUpload"
                            :on-success="handleAvatarSuccess"
                            :on-error="handleError"
                            :on-preview="handlePictureCardPreview"
                            :before-remove="beforeRemove"
                            :on-remove="handleRemove"
                            :class="{hide:hideUpload}"
                            :limit="1"
                            :file-list="pic">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="行驶证:" label-width="75px">
                    <el-upload
                            :action="uploadQiniuUrl"
                            :data="picData2"
                            ref="upload"
                            list-type="picture-card"
                            :before-upload="beforeUpload2"
                            :on-success="handleAvatarSuccess2"
                            :on-error="handleError"
                            :on-preview="handlePictureCardPreview"
                            :before-remove="beforeRemove"
                            :on-remove="handleRemove2"
                            :class="{hide2:hideUpload2}"
                            :limit="1"
                            :file-list="pic2">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保单照片:" label-width="75px">
                    <el-upload
                            :action="uploadQiniuUrl"
                            :data="picData3"
                            ref="upload"
                            list-type="picture-card"
                            :before-upload="beforeUpload3"
                            :on-success="handleAvatarSuccess3"
                            :on-error="handleError"
                            :on-preview="handlePictureCardPreview"
                            :before-remove="beforeRemove"
                            :on-remove="handleRemove3"
                            :class="{hide3:hideUpload3}"
                            :limit="1"
                            :file-list="pic3">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div slot="footer">
              <el-button @click="addCar = false">取 消</el-button>
              <el-button type="primary" @click="addCarSave" v-if="title == '添加车辆'">保 存</el-button>
              <el-button type="primary" @click="addCarModify" v-else>保 存</el-button>
            </div>
          </el-dialog>
        <el-button type="primary" @click="xiugaiziliaoButton" v-if="authe.Hotel_RegionalDivision_Vehicle_ModifyInfo" size="mini">修改资料</el-button>&nbsp;
          <el-dialog :visible.sync="dialogVehicleDetails" @close="cleanDialogDetails" :close-on-click-modal="false" width="900px">
            <el-tabs v-model="tab" class="classDialog" @tab-click="tabChange">
              <el-tab-pane label="车辆详情" name="first">
                <el-form>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="车辆用途:" label-width="75px">
                        {{addVehicle.svrVehicleUse}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="车辆类型:" label-width="75px">
                        {{addVehicle.svrVehicleType}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="下次年审:" label-width="75px">
                        {{addVehicle.svrAnnualReview}}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="车牌号码:" label-width="75px">
                        {{addVehicle.svrVehicleNumber}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item prop="svrTotalMileage" label="总里程:" label-width="75px">
                        {{addVehicle.svrTotalMileage}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="下次保养:" label-width="75px">
                        {{addVehicle.svrMaintain}}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="使用性质:" label-width="75px">
                        {{addVehicle.svrCatalog}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item prop="svrFrameNumber" label="车架号:" label-width="75px">
                        {{addVehicle.svrFrameNumber}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="注册时间:" label-width="75px">
                        {{addVehicle.svrRegistrationTime}}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="车辆油量:" label-width="75px">
                        {{addVehicle.svrCarBattery}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item prop="svrFrameNumber" label="车辆油量:" label-width="75px">
                        {{addVehicle.svrCarOil}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="车险到期:" label-width="75px">
                        {{addVehicle.svrInsurancePeriod}}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="品牌型号:" label-width="75px">
                        {{addVehicle.svrBrandModel}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item prop="svrNuclearLoading" label="核载人数:" label-width="75px">
                        {{addVehicle.svrNuclearLoading}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="负责人:" label-width="75px">
                        {{addVehicle.suStaffName}}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="16">
                      <el-form-item label="注册地址:" label-width="75px">
                        {{addVehicle.svrRegisteredAddress}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item prop="svrEngineNumber" label="发动机号:" label-width="75px">
                        {{addVehicle.svrEngineNumber}}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="保险公司:" label-width="75px">
                        {{addVehicle.svrInsuranceCompany}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="保单号:" label-width="75px">
                        {{addVehicle.svrPolicyNumber}}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="车辆照片:" label-width="75px">
                        <el-upload
                                :action="uploadQiniuUrl"
                                :data="picData"
                                ref="upload"
                                list-type="picture-card"
                                :before-upload="beforeUpload"
                                :on-success="handleAvatarSuccess"
                                :on-error="handleError"
                                :on-preview="handlePictureCardPreview"
                                :before-remove="beforeRemove"
                                :on-remove="handleRemove"
                                :class="{hide6:hideUpload6}"
                                :limit="1"
                                :file-list="pic">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="行驶证:" label-width="75px">
                        <el-upload
                                :action="uploadQiniuUrl"
                                :data="picData2"
                                ref="upload"
                                list-type="picture-card"
                                :before-upload="beforeUpload2"
                                :on-success="handleAvatarSuccess2"
                                :on-error="handleError"
                                :on-preview="handlePictureCardPreview"
                                :before-remove="beforeRemove"
                                :on-remove="handleRemove2"
                                :class="{hide6:hideUpload6}"
                                :limit="1"
                                :file-list="pic2">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="保单照片:" label-width="75px">
                        <el-upload
                                :action="uploadQiniuUrl"
                                :data="picData3"
                                ref="upload"
                                list-type="picture-card"
                                :before-upload="beforeUpload3"
                                :on-success="handleAvatarSuccess3"
                                :on-error="handleError"
                                :on-preview="handlePictureCardPreview"
                                :before-remove="beforeRemove"
                                :on-remove="handleRemove3"
                                :class="{hide6:hideUpload6}"
                                :limit="1"
                                :file-list="pic3">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="历史记录" name="second">
                <!-- 内容表格 -->
                <el-table
                        :data="historyData"
                        @current-change="currentChangeHistory"
                        @row-dblclick="doubleClickHistory"
                        highlight-current-row
                        style="width:100%;">
                  <el-table-column prop="svmVehicleType" label="管理类型" min-width="100px"></el-table-column>
                  <el-table-column prop="svmLiftCarOil" label="提车油量" min-width="100px"></el-table-column>
                  <el-table-column prop="svmStillCarBattery" label="提车电量" min-width="100px"></el-table-column>
                  <el-table-column prop="svmLiftCarMileage" label="提车里程" min-width="100px"></el-table-column>
                  <el-table-column prop="svmStillCarOil" label="归还油量" min-width="100px"></el-table-column>
                  <el-table-column prop="svmStillCarBattery" label="归还点量" min-width="100px"></el-table-column>
                  <el-table-column prop="svmStillCarMileage" label="归还里程" min-width="100px"></el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-dialog>
          <!--        历史记录详情-->
          <el-dialog title="记录详情" :visible.sync="dialogHistory" :close-on-click-modal="false" width="900px">
            <el-form v-if="historyXqObj.svmVehicleType == '移交'">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆号:" label-width="75px">
                    {{historyXqObj.svrVehicleNumber}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="管理类型:" label-width="75px">
                    {{historyXqObj.svmVehicleType}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="移交原因:" label-width="75px">
                    {{historyXqObj.svmReasons}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="提车油量:" label-width="75px">
                    {{historyXqObj.svmLiftCarOil}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="提车电量:" label-width="75px">
                    {{historyXqObj.svmLiftCarBattery}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="提车里程:" label-width="75px">
                    {{historyXqObj.svmLiftCarMileage}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="还车油量:" label-width="75px">
                    {{historyXqObj.svmStillCarOil}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="还车电量:" label-width="75px">
                    {{historyXqObj.svmStillCarBattery}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="还车里程:" label-width="75px">
                    {{historyXqObj.svmStillCarMileage}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="证件移交:" label-width="75px">
                    {{historyXqObj.svmTransferCertificates}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="管理时间:" label-width="75px">
                    {{historyXqObj.svmCreateTime}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="归还时间:" label-width="75px">
                    {{historyXqObj.svmUpdateTime}}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-form v-if="historyXqObj.svmVehicleType == '保养'">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆号:" label-width="75px">
                    {{historyXqObj.svrVehicleNumber}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="管理类型:" label-width="75px">
                    {{historyXqObj.svmVehicleType}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保养项目:" label-width="75px">
                    {{historyXqObj.svmMaintenanceProgram}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="提车油量:" label-width="75px">
                    {{historyXqObj.svmLiftCarOil}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="提车电量:" label-width="75px">
                    {{historyXqObj.svmLiftCarBattery}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="提车里程:" label-width="75px">
                    {{historyXqObj.svmLiftCarMileage}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="还车油量:" label-width="75px">
                    {{historyXqObj.svmStillCarOil}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="还车电量:" label-width="75px">
                    {{historyXqObj.svmStillCarBattery}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="还车里程:" label-width="75px">
                    {{historyXqObj.svmStillCarMileage}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="保养单位:" label-width="75px">
                    {{historyXqObj.svmCompany}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="管理时间:" label-width="75px">
                    {{historyXqObj.svmCreateTime}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="归还时间:" label-width="75px">
                    {{historyXqObj.svmUpdateTime}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="保养原因:" label-width="75px">
                    {{historyXqObj.svmReasons}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保养费用:" label-width="75px">
                    {{historyXqObj.svmMaintenanceCost}}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-form v-if="historyXqObj.svmVehicleType == '年审'">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆号:" label-width="75px">
                    {{historyXqObj.svrVehicleNumber}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="管理类型:" label-width="75px">
                    {{historyXqObj.svmVehicleType}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="验车单位:" label-width="75px">
                    {{historyXqObj.svmCompany}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="提车油量:" label-width="75px">
                    {{historyXqObj.svmLiftCarOil}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="提车电量:" label-width="75px">
                    {{historyXqObj.svmLiftCarBattery}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="提车里程:" label-width="75px">
                    {{historyXqObj.svmLiftCarMileage}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="还车油量:" label-width="75px">
                    {{historyXqObj.svmStillCarOil}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="还车电量:" label-width="75px">
                    {{historyXqObj.svmStillCarBattery}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="还车里程:" label-width="75px">
                    {{historyXqObj.svmStillCarMileage}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="本次时间:" label-width="75px">
                    {{historyXqObj.svmTheDate}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="管理时间:" label-width="75px">
                    {{historyXqObj.svmCreateTime}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="归还时间:" label-width="75px">
                    {{historyXqObj.svmUpdateTime}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="下次时间:" label-width="75px">
                    {{historyXqObj.svmNextDate}}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-dialog>
        <el-button type="primary" @click="carHandOverBuuton" v-if="authe.Hotel_RegionalDivision_Vehicle_VehicleHandover" size="mini">车辆移交</el-button>&nbsp;
          <el-dialog title="车辆负责人移交" :visible.sync="carHandOver" @close="cleanDialogHandOver" :close-on-click-modal="false" width="800px">
            <el-form>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆号:" label-width="75px">
                    <el-select v-model="carHandOverData.vehicle" @change="vehicleChe" placeholder="请选择" size="mini">
                      <el-option
                              v-for="item in vehicleNumberData"
                              :key="item.svrId"
                              :label="item.svrVehicleNumber"
                              :value="item.svrId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="新负责人:" label-width="75px">
                    <el-select v-model="carHandOverData.newHead" placeholder="请选择" size="mini">
                      <el-option
                              v-for="item in userData"
                              :key="item.userCoding"
                              :label="item.suStaffName"
                              :value="item.userCoding">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="证件移交:" label-width="75px">
                    <el-select v-model="carHandOverData.documentsHand" size="mini">
                      <el-option label="是" value="是"></el-option>
                      <el-option label="否" value="否"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="提车油量:" label-width="75px">
                    <el-input v-model="maintenanceData.svmLiftCarOil" disabled size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="提车电量:" label-width="75px">
                    <el-input v-model="maintenanceData.svmLiftCarBattery" disabled size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="提车里程:" label-width="80px">
                    <el-input v-model.number="maintenanceData.svmLiftCarMileage" disabled size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="16">
                  <el-form-item label="移交原因:" label-width="75px">
                    <el-input v-model="carHandOverData.transferReason" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div slot="footer">
              <el-button @click="carHandOver = false">取 消</el-button>
              <el-button type="primary" @click="carHandOverSave">保 存</el-button>
            </div>
          </el-dialog>
        <el-button type="primary" @click="maintenanceCarButton" v-if="authe.Hotel_RegionalDivision_Vehicle_CarCare" size="mini">车辆保养</el-button>&nbsp;
          <el-dialog title="车辆保养" :visible.sync="maintenanceCar" @close="cleanDialogMaintenance" :close-on-click-modal="false" width="800px">
            <el-form>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆选择" label-width="75px">
                    <el-select v-model="carHandOverData.vehicle" @change="vehicleChe" placeholder="请选择" size="mini">
                      <el-option
                              v-for="item in vehicleNumberData"
                              :key="item.svrId"
                              :label="item.svrVehicleNumber"
                              :value="item.svrId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保养项目:" label-width="75px">
                    <el-input v-model="maintenanceData.svmMaintenanceProgram" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保养费用:" label-width="75px">
                    <el-input v-model="maintenanceData.svmMaintenanceCost" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="16">
                  <el-form-item label="保养原因:" label-width="75px">
                    <el-input v-model="maintenanceData.svmReasons" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保养日期:" label-width="75px">
                    <el-date-picker
                            v-model="maintenanceData.svmOldDate"
                            type="date"
                            size="mini"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width:100%"
                            placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="16">
                  <el-form-item label="保养单位:" label-width="75px">
                    <el-input v-model="maintenanceData.svmCompany" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrTotalMileage" label="提车人:" label-width="75px">
                    <el-select v-model="maintenanceData.svmLiftCarMan" placeholder="请选择" size="mini">
                      <el-option
                              v-for="item in userData"
                              :key="item.userCoding"
                              :label="item.suStaffName"
                              :value="item.userCoding">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="提车油量:" label-width="75px">
                    <el-input v-model="maintenanceData.svmLiftCarOil" disabled size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="提车电量:" label-width="75px">
                    <el-input v-model="maintenanceData.svmLiftCarBattery" disabled size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="提车里程:" label-width="80px">
                    <el-input v-model.number="maintenanceData.svmLiftCarMileage" disabled size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <!--              <el-col :span="8">-->
                <!--                <el-form-item label="交车外观:" label-width="75px">-->
                <!--                  <el-upload-->
                <!--                          :action="uploadQiniuUrl"-->
                <!--                          :data="picIncarData"-->
                <!--                          ref="upload"-->
                <!--                          list-type="picture-card"-->
                <!--                          :before-upload="beforeUploadIncar"-->
                <!--                          :on-success="handleAvatarSuccessIncar"-->
                <!--                          :on-error="handleError"-->
                <!--                          :on-preview="handlePictureCardPreview"-->
                <!--                          :before-remove="beforeRemoveIncar"-->
                <!--                          :on-remove="handleRemoveIncar"-->
                <!--                          :class="{hide4:hideUpload4}"-->
                <!--                          :limit="1"-->
                <!--                          :file-list="picIncar">-->
                <!--                    <i class="el-icon-plus"></i>-->
                <!--                  </el-upload>-->
                <!--                </el-form-item>-->
                <!--              </el-col>-->
                <el-col :span="8">
                  <el-form-item label="提车外观:" label-width="75px">
                    <el-upload
                            :action="uploadQiniuUrl"
                            :data="picOutcarData"
                            ref="upload"
                            list-type="picture-card"
                            :before-upload="beforeUploadOutcar"
                            :on-success="handleAvatarSuccessOutcar"
                            :on-error="handleError"
                            :on-preview="handlePictureCardPreview"
                            :before-remove="beforeRemoveIncar"
                            :on-remove="handleRemoveOutcar"
                            :class="{hide5:hideUpload5}"
                            :limit="1"
                            :file-list="picOutcar">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div slot="footer">
              <el-button @click="maintenanceCar = false">取 消</el-button>
              <el-button type="primary" @click="maintenanceCarSave">保 存</el-button>
            </div>
          </el-dialog>
        <el-button type="primary" @click="yearCarefulButton" v-if="authe.Hotel_RegionalDivision_Vehicle_AnnualVehicleReview" size="mini">车辆年审</el-button>&nbsp;
          <el-dialog title="车辆年审登记" :visible.sync="yearCareful" @close="cleanDialogCareful" :close-on-click-modal="false" width="800px">
            <el-form>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆号:" label-width="75px">
                    <el-select v-model="carHandOverData.vehicle" @change="vehicleChe" placeholder="请选择" size="mini">
                      <el-option
                              v-for="item in vehicleNumberData"
                              :key="item.svrId"
                              :label="item.svrVehicleNumber"
                              :value="item.svrId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="本次时间:" label-width="75px">
                    <el-date-picker
                            v-model="yearCarefulData.nextMileage"
                            type="date"
                            size="mini"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width:100%"
                            placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="下次时间:" label-width="75px">
                    <el-date-picker
                            v-model="yearCarefulData.nextTime"
                            type="date"
                            size="mini"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width:100%"
                            placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="提车油量:" label-width="75px">
                    <el-input v-model="maintenanceData.svmLiftCarOil" disabled size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="提车电量:" label-width="75px">
                    <el-input v-model="maintenanceData.svmLiftCarBattery" disabled size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="提车里程:" label-width="80px">
                    <el-input v-model.number="maintenanceData.svmLiftCarMileage" disabled size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="16">
                  <el-form-item label="验车单位:" label-width="75px">
                    <el-input v-model="yearCarefulData.carUnit" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="负责人:" label-width="75px">
                    <el-select v-model="yearCarefulData.approver" placeholder="请选择" size="mini">
                      <el-option
                              v-for="item in userData"
                              :key="item.userCoding"
                              :label="item.suStaffName"
                              :value="item.userCoding">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div slot="footer">
              <el-button @click="yearCareful = false">取 消</el-button>
              <el-button type="primary" @click="yearCarefulSave">保 存</el-button>
            </div>
          </el-dialog>
          <el-dialog title="归还详情" :visible.sync="returnCarDialog" @close="cleanDialogReturn" :close-on-click-modal="false" width="800px">
            <el-form v-if="returnDetails.svmVehicleType == '移交'">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆号:" label-width="75px">
                    {{returnDetails.svrVehicleNumber}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="证件移交:" label-width="75px">
                    {{returnDetails.svmTransferCertificates}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="移交原因:" label-width="75px">
                    {{returnDetails.svmVehicleType}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="提车油量:" label-width="75px">
                    {{returnDetails.svmLiftCarOil}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="提车电量:" label-width="75px">
                    {{returnDetails.svmLiftCarBattery}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="提车里程:" label-width="80px">
                    {{returnDetails.svmLiftCarMileage}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="还车油量:" label-width="75px">
                    <el-input v-model="alsoRemaining.svmStillCarOil" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="还车电量:" label-width="75px">
                    <el-input v-model="alsoRemaining.svmStillCarBattery" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="还车里程:" label-width="80px">
                    <el-input v-model.number="alsoRemaining.svmStillCarMileage" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-form v-else-if="returnDetails.svmVehicleType == '年审'">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆号:" label-width="75px">
                    {{returnDetails.svrVehicleNumber}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="本次时间:" label-width="75px">
                    {{returnDetails.svmTheDate}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="下次时间:" label-width="75px">
                    {{returnDetails.svmNextDate}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="提车油量:" label-width="75px">
                    {{returnDetails.svmLiftCarOil}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="提车电量:" label-width="75px">
                    {{returnDetails.svmLiftCarBattery}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="提车里程:" label-width="80px">
                    {{returnDetails.svmLiftCarMileage}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="16">
                  <el-form-item label="验车单位:" label-width="75px">
                    {{returnDetails.svmCompany}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="还车油量:" label-width="75px">
                    <el-input v-model="alsoRemaining.svmStillCarOil" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="还车电量:" label-width="75px">
                    <el-input v-model="alsoRemaining.svmStillCarBattery" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="还车里程:" label-width="80px">
                    <el-input v-model.number="alsoRemaining.svmStillCarMileage" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-form v-else-if="returnDetails.svmVehicleType == '保养'">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆号:" label-width="75px">
                    {{returnDetails.svrVehicleNumber}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保养项目:" label-width="75px">
                    {{returnDetails.svmMaintenanceProgram}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保养费用:" label-width="75px">
                    {{returnDetails.svmMaintenanceCost}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="16">
                  <el-form-item label="保养原因:" label-width="75px">
                    {{returnDetails.svmReasons}}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="保养单位:" label-width="75px">
                    {{returnDetails.svmCompany}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="16">
                  <el-form-item label="验车单位:" label-width="75px">
                    {{returnDetails.svmCompany}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="还车油量:" label-width="75px">
                    <el-input v-model="alsoRemaining.svmStillCarOil" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="还车电量:" label-width="75px">
                    <el-input v-model="alsoRemaining.svmStillCarBattery" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="svrFrameNumber" label="还车里程:" label-width="80px">
                    <el-input v-model.number="alsoRemaining.svmStillCarMileage" size="mini" style="width:100%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div slot="footer">
              <el-button @click="returnCarDialog = false">取 消</el-button>
              <el-button type="primary" @click="returnCarPut">归 还</el-button>
            </div>
          </el-dialog>
      </el-row><!--功能按钮-->
      <el-row style="padding: 5px 5px 5px 0;">
        车牌查询：<el-input v-model="vehicle.licensePlate" @change="vehicleRecord" size="mini" clearable style="width:15%"></el-input>
      </el-row>
      <!--      车辆照片的图片展示-->
      <el-dialog :visible.sync="dialogVisible1">
        <img width="100%" :src="dialogImageUrl1" alt="">
      </el-dialog>
    </div>
    <!-- 内容表格 -->
    <el-table
            v-loading="loading"
            :data="vehicleDataList"
            @current-change="currentChange"
            @row-click="tableClick"
            @row-dblclick="doubleClick"
            highlight-current-row
            :height='tableHeight' style="width:100%;">
      <el-table-column prop="svrVehicleUse" label="用途" min-width="100px"></el-table-column>
      <el-table-column prop="svrVehicleNumber" label="车牌号" min-width="100px"></el-table-column>
      <el-table-column prop="svrVehicleType" label="车辆类型" min-width="100px"></el-table-column>
      <el-table-column prop="svrCatalog" label="使用性质" min-width="100px"></el-table-column>
      <el-table-column prop="svrBrandModel" label="品牌" min-width="100px"></el-table-column>
      <el-table-column prop="svrNuclearLoading" label="核载人数" min-width="100px"></el-table-column>
      <el-table-column prop="suStaffName" label="负责人" min-width="100px"></el-table-column>
      <el-table-column prop="driverName" label="司机" min-width="100px"></el-table-column>
      <el-table-column label="状态" min-width="100px" align="center">
        <template slot-scope="scope">
          <el-button v-if="scope.row.svrCurrentState != '空置'" @click="returnCar(scope.row)" size="mini" style="font-size: 13px!important;">归还</el-button>
          <span v-else>{{scope.row.svrCurrentState}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
            background layout="total, sizes, prev, pager, next, jumper"
            align="center"
            :page-count="pages"
            :page-size="size"
            :total="total"
            :current-page="current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :page-sizes="[5,10,20,40]"
    ></el-pagination>
  </div>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    name:'vehicleManagement',
    data(){
      return{
        rules: {//验证规则
          svrVehicleNumber: [
            {required: true,message: '车辆号码不能为空',trigger: 'blur',},
          ],
          svrTotalMileage: [
            {required: true,message: '总里程不能为空',trigger: 'blur',},
          ],
          svrFrameNumber: [
            {required: true,message: '车架号不能为空',trigger: 'blur',},
          ],
          svrNuclearLoading: [
            {required: true,message: '核载人数不能为空',trigger: 'blur',},
          ],
          svrEngineNumber: [
            {required: true,message: '发动机号不能为空',trigger: 'blur',},
          ],
        },
        tableHeight: window.innerHeight - 225,
        loading: false,
        title: '',
        addCar: false,
        carHandOver: false,
        yearCareful: false,
        row: '',
        maintenanceCar: false,//车辆保养弹框

        dialogImageUrl1: '',
        dialogVisible1: false,  //上传照片
        dialogImageUrl3: '',
        dialogVisible3: false,  //上传照片
        //车辆照片
        picData:{             //上传图片携带的参数
          token : "",
          "x:co": "",
          "x:filename": "",
          "x:photoRandom": "", //十二位随机数
          "x:photoTableType": "", //审批or任务
          "x:photoMimeType": "", //文件or图片
          "x:photoBucket": "" ,//公用or私有
          "x:photoTableId": "" //外表id
        },
        uploadQiniuUrl:"https://upload-z2.qiniup.com",   //七牛云服务器地址
        pic:[],
        limitCount:1,
        hideUpload: false,

        //行驶证
        picData2:{             //上传图片携带的参数
          token : "",
          "x:co": "",
          "x:filename": "",
          "x:photoRandom": "", //十二位随机数
          "x:photoTableType": "", //审批or任务
          "x:photoMimeType": "", //文件or图片
          "x:photoBucket": "" ,//公用or私有
          "x:photoTableId": "" //外表id
        },
        pic2:[],
        limitCount2:1,
        hideUpload2: false,

        //保单照片
        picData3:{             //上传图片携带的参数
          token : "",
          "x:co": "",
          "x:filename": "",
          "x:photoRandom": "", //十二位随机数
          "x:photoTableType": "", //审批or任务
          "x:photoMimeType": "", //文件or图片
          "x:photoBucket": "" ,//公用or私有
          "x:photoTableId": "" //外表id
        },
        pic3:[],
        limitCount3:1,
        hideUpload3: false,

        vehicle:{
          licensePlate: '',
        },
        userData: [],
        addVehicle:{
          svrCarOil: '',//车油量
          svrCarBattery: '',//车电量
          suStaffName: '',
          svrVehicleUse: '',//车辆用途
          svrVehicleType: '',//车辆类型
          svrAnnualReview: '',//下次年审
          svrVehicleNumber: '',//车牌号码
          svrTotalMileage: '',//总里程
          svrMaintain: '',//下次保养
          svrCatalog: '',//使用性质
          svrFrameNumber: '',//车架号
          svrRegistrationTime: '',//注册时间
          svrBrandModel: '',//品牌型号
          svrNuclearLoading: '',//核载人数
          userCoding: '',//负责人
          svrRegisteredAddress: '',//注册地址
          svrEngineNumber: '',//发动机号
          svrInsuranceCompany: '',//保险公司
          svrPolicyNumber: '',//保单号
          svrInsurancePeriod: '',//车险到期
          tableTypes: ['车辆照片','行驶证','保单照片'],//外表类型
          randomNum: '',//随机数
          deleteImgList: [],//删除图片列表
        },
        //车辆保养相关
        maintenanceData: {
          svmVehicleId: '',
          svmMaintenanceProgram: '',
          svmMaintenanceCost: '',
          svmOldDate: '',
          svmReasons: '',
          svmCompany: '',
          svmLiftCarMan: '',
          svmLiftCarOil: '',
          svmLiftCarBattery: '',
          svmLiftCarMileage: '',
          tableTypes: ['提车外观'],//外表类型
          randomNum: '',//随机数
          svmVehicleType: '保养'
        },
        //交车外观
        picIncarData:{             //上传图片携带的参数
          token : "",
          "x:co": "",
          "x:filename": "",
          "x:photoRandom": "", //十二位随机数
          "x:photoTableType": "", //审批or任务
          "x:photoMimeType": "", //文件or图片
          "x:photoBucket": "" ,//公用or私有
          "x:photoTableId": "" //外表id
        },
        picIncar:[],
        limitCount4:1,
        hideUpload4: false,
        //提车外观
        picOutcarData:{             //上传图片携带的参数
          token : "",
          "x:co": "",
          "x:filename": "",
          "x:photoRandom": "", //十二位随机数
          "x:photoTableType": "", //审批or任务
          "x:photoMimeType": "", //文件or图片
          "x:photoBucket": "" ,//公用or私有
          "x:photoTableId": "" //外表id
        },
        picOutcar:[],
        limitCount5:1,
        hideUpload5: false,

        hideUpload6: false,

        //车辆移交相关
        carHandOverData: {
          vehicle: '',//车辆号
          mileage: '',//移交里程
          newHead: '',//新负责人
          transferReason: '',//原因
          documentsHand: '',//证件移交
        },
        vehicleNumberData: [],

        yearCarefulData:{
          nextMileage: '',
          nextTime: '',
          carUnit: '',
          approver: '',
        },

        //查看详情相关
        tab: 'first',
        dialogVehicleDetails: false,

        //还车
        returnState: '',
        returnCarDialog: false,
        returnDetails: '',
        alsoRemaining: {
          svmVehicleId: '',
          svmStillCarOil: '',
          svmStillCarBattery: '',
          svmStillCarMileage: '',
          tableTypes: ['归还']
        },

        //历史记录
        historyData: [],
        historyXqObj: '',
        rowHistory: '',
        dialogHistory: false,

        total: 0,         //总条数
        size: 10,        //每页的条数
        pages: 0,      //每页数据
        current: 0,    //初始页

        vehicleDataList: [],
        tableRow: {},
      }
    },
    created() {
      if (this.pageAuth['/vehicleManagement']) {
        this.vehicleRecord()
        this.users()
      } else {
        this.$router.replace('/notfind')
      }
    },
    methods: {
      resetForm(formName) {
        setTimeout(() => {
          // this.$refs[formName].resetFields();//清除name
          this.$refs[formName].clearValidate();//不清除name
        },1)
      },
      vehicleRecord(){
        this.$axios({
          method: 'get',
          url: `/common1/vehicleRegistrations`,
          params: {
            svrVehicleNumber: this.vehicle.licensePlate,
            cursor: this.current,
            limit: this.size,
          }
        }).then(res => {
          console.log(res)
          this.vehicleDataList = res.data.result.records
          this.total=res.data.result.total;
          this.size=res.data.result.size;
          this.pages=res.data.result.pages;
          this.current=res.data.result.current;
        }).catch(err => {
          this.loading = false
          this.isError(err,this)
        })
      },
      addCarButton(){ //添加车辆
        this.addCar = true
        this.title = '添加车辆'
        this.addVehicle.randomNum = parseInt((Math.random() * 9 + 1) * 100000000000)
      },
      users(){//查询用户信息
        this.$axios({
          method: 'get',
          url: `/common1/users`
        }).then(res => {
          console.log(res)
          this.userData = res.data.result.records
        }).catch(err => {
          this.isError(err,this)
        })
      },
      //获取下载凭证
      getDownloadToken(str) {
        return this.$axios({
          url: `/common1/photos/getDownloadUrl`,
          method: "get",
          params: {
            baseUrls: str
          }
        }).then(res => {
          return res.data.result[0].original;
        }).catch(err => {
          this.isError(err,this)
        })
      },
      //恢复图片
      recoverImg() {
        console.log(this.row.svrId)
        if(this.row.svrId){
          let array = ["车辆照片",'行驶证','保单照片']
          for (let i of array) {
            console.log("进来了     " + i )
            this.$axios({
              url: `/common1/photos/recovery/${this.row.svrId}`,
              method: "PUT",
              params: {
                photoTableType: i
              }
            }).then(res => {

            }).catch(err => {
              this.isError(err,this)
            })
          }
        }
      },
      //上传图片
      getToken(){
        return this.$axios({
          url: "/common1/photos/token/callback",
          method: "get",
          params: {
            bucket: "私有"
          }
        }).then(res => {
          return res.data.result;
        }).catch(err => {
          this.isError(err,this)
        })
      },
      async beforeUpload(file){//上传完文件前获取token
        try {
          let getToken = await this.getToken();
          this.picData.token = getToken;
          if(file.type == "jpeg"){
            return false
          }else{
            this.picData["x:photoMimeType"] = "图片"
          }
          this.picData["x:filename"] = file.name;
          this.picData["x:co"] = sessionStorage.getItem("co");
          this.picData["x:photoTableType"] = "车辆照片";
          this.picData["x:photoBucket"] = "私有";
          if(this.title == '添加车辆'){
            this.picData["x:photoTableId"] = '';
          }else{
            this.picData["x:photoTableId"] = this.row.svrId
          }
          this.picData["x:photoRandom"] = this.addVehicle.randomNum;
        } catch (err) {
          console.log(err);
        }
      },
      handleAvatarSuccess(res, file,fileList) {//上传成功后在图片框显示图片
        this.pic = fileList;
        this.hideUpload = fileList.length >= this.limitCount;
      },
      handleError(res,fileList) {//上传照片失败显示错误
        console.log(res)
      },
      handlePictureCardPreview(file) {//查看某张图片的原图
        this.dialogImageUrl1 = file.url;
        this.dialogVisible1 = true;
      },
      beforeRemove(file) {//删除前
        if (this.title == "添加车辆") {
          console.log('if');
          return this.$confirm(`确定移除该文件？`).then(() => {
            this.$axios({
              url: `/common1/photos`,
              method: "DELETE",
              params: {
                bucket: "私有",
                photoIds: file.response.result
              }
            }).then(res => {
              console.log(res);
            }).catch(err => {
              this.isError(err,this)
            });
          });
        }else {
          return this.$confirm(`确定移除该文件？`).then(() => {
            this.addVehicle.deleteImgList.push(file.response.result);
            this.$axios({
              url: `/common1/photos/failure/${file.response.result}`,
              method: "put"
            }).then(res => {

            }).catch(err => {
              this.isError(err,this)
            })
          });
        }
      },
      handleRemove(file,fileList){//删除后
        console.log(fileList)
        this.pic=fileList
        this.hideUpload = fileList.length >= this.limitCount;
      },

      //行驶证照片相关
      async beforeUpload2(file){//上传完文件前获取token
        try {
          let getToken = await this.getToken();
          this.picData2.token = getToken;
          if(file.type == "jpeg"){
            return false
          }else{
            this.picData2["x:photoMimeType"] = "图片"
          }
          this.picData2["x:filename"] = file.name;
          this.picData2["x:co"] = sessionStorage.getItem("co");
          this.picData2["x:photoTableType"] = "行驶证";
          this.picData2["x:photoBucket"] = "私有";
          if(this.title == '添加车辆'){
            this.picData2["x:photoTableId"] = '';
          }else{
            this.picData2["x:photoTableId"] = this.row.svrId
          }
          this.picData2["x:photoRandom"] = this.addVehicle.randomNum;
        } catch (err) {
          console.log(err);
        }
      },
      handleAvatarSuccess2(res, file,fileList) {//上传成功后在图片框显示图片
        this.pic2 = fileList;
        this.hideUpload2 = fileList.length >= this.limitCount2;
      },
      handleRemove2(file,fileList){//删除后
        this.pic2=fileList
        this.hideUpload2 = fileList.length >= this.limitCount2;
      },

      //保单照片相关
      async beforeUpload3(file){//上传完文件前获取token
        try {
          let getToken = await this.getToken();
          this.picData3.token = getToken;
          if(file.type == "jpeg"){
            return false
          }else{
            this.picData3["x:photoMimeType"] = "图片"
          }
          this.picData3["x:filename"] = file.name;
          this.picData3["x:co"] = sessionStorage.getItem("co");
          this.picData3["x:photoTableType"] = "保单照片";
          this.picData3["x:photoBucket"] = "私有";
          if(this.title == '添加车辆'){
            this.picData3["x:photoTableId"] = '';
          }else{
            this.picData3["x:photoTableId"] = this.row.svrId
          }
          this.picData3["x:photoRandom"] = this.addVehicle.randomNum;
        } catch (err) {
          console.log(err);
        }
      },
      handleAvatarSuccess3(res, file,fileList) {//上传成功后在图片框显示图片
        this.pic3 = fileList;
        this.hideUpload3 = fileList.length >= this.limitCount3;
      },
      handleRemove3(file,fileList){//删除后
        this.pic3=fileList
        this.hideUpload3 = fileList.length >= this.limitCount3;
      },

      addCarSave(){//添加车辆保存
        console.log(this.addVehicle);
        if(this.submitForm(['roomForm'],this)){
          this.loading = true
          this.$axios({
            method: 'post',
            url: `/common1/vehicleRegistrations`,
            data: this.addVehicle
          }).then(res => {
            this.loading = false
            this.addCar = false
            this.$message.success('添加成功')
            this.vehicleRecord()
          }).catch(err => {
            this.loading = false
            this.addCar = false
            this.isError(err,this)
          })
        }
      },
      //查询已保存的图片
      async getPhoto() {
        try {
          let imgArr = [];
          let imgdata = [];
          await this.$axios({
            url: `/common1/photos`,
            method: "get",
            params: {
              photoTableId: this.row.svrId,
              photoTableType: '车辆照片',
              photoStatus: "正常"
            }
          }).then(res => {
            imgdata = res.data.result;
          }).catch(err => {
            this.isError(err,this)
          })
          for (let i = 0; i < imgdata.length; i++) {
            let obj = {
              name: imgdata[i].photoName,
              url: await this.getDownloadToken(imgdata[i].photoUrl),
              response: {
                result: imgdata[i].photoId
              }
            };
            imgArr.push(obj);
          }
          console.log(imgArr)
          this.pic = imgArr;
          this.hideUpload = this.pic.length >= this.limitCount;
        } catch (err) {
          console.log(err);
        }
      },
      //查询已保存行驶证的图片
      async getPhotoLicense() {
        try {
          let imgArr = [];
          let imgdata = [];
          await this.$axios({
            url: `/common1/photos`,
            method: "get",
            params: {
              photoTableId: this.row.svrId,
              photoTableType: '行驶证',
              photoStatus: "正常"
            }
          }).then(res => {
            imgdata = res.data.result;
          }).catch(err => {
            this.isError(err,this)
          })
          for (let i = 0; i < imgdata.length; i++) {
            let obj = {
              name: imgdata[i].photoName,
              url: await this.getDownloadToken(imgdata[i].photoUrl),
              response: {
                result: imgdata[i].photoId
              }
            };
            imgArr.push(obj);
          }
          this.pic2 = imgArr;
          this.hideUpload2 = this.pic2.length >= this.limitCount2;
        } catch (err) {
          console.log(err);
        }
      },
      //查询已保存行驶证的图片
      async getPhotoPolicy() {
        try {
          let imgArr = [];
          let imgdata = [];
          await this.$axios({
            url: `/common1/photos`,
            method: "get",
            params: {
              photoTableId: this.row.svrId,
              photoTableType: '保单照片',
              photoStatus: "正常"
            }
          }).then(res => {
            imgdata = res.data.result;
          }).catch(err => {
            this.isError(err,this)
          })
          for (let i = 0; i < imgdata.length; i++) {
            let obj = {
              name: imgdata[i].photoName,
              url: await this.getDownloadToken(imgdata[i].photoUrl),
              response: {
                result: imgdata[i].photoId
              }
            };
            imgArr.push(obj);
          }
          this.pic3 = imgArr;
          this.hideUpload3 = this.pic3.length >= this.limitCount3;
        } catch (err) {
          console.log(err);
        }
      },
      xiugaiziliaoButton(){ //查看资料赋值
        if(this.row){
          this.title = '修改资料'
          this.addVehicle.randomNum = parseInt((Math.random() * 9 + 1) * 100000000000)
          this.$axios({
            method: 'get',
            url: `/common1/vehicleRegistrations/${this.row.svrId}`
          }).then(res => {
            console.log(res)
            for(let i in res.data.result){
              for(let j in this.addVehicle){
                if(i === j){
                  this.addVehicle[j] = res.data.result[i]
                }
              }
            }
            if(res.data.result.userCoding){
              this.addVehicle.userCoding = res.data.result.userCoding.toString()
            }
            this.getPhoto()
            this.getPhotoLicense()
            this.getPhotoPolicy()
            this.addCar = true
          }).catch(err => {
            this.isError(err,this)
          })
        }else{
          this.$message.warning('请选择一条记录')
        }
      },
      addCarModify(){//修改资料
        this.$axios({
          method: 'put',
          url: `/common1/vehicleRegistrations/${this.row.svrId}`,
          data: this.addVehicle
        }).then(res => {
          this.loading = false
          this.addCar = false
          this.$message.success('修改成功')
          this.vehicleRecord()
        }).catch(err => {
          this.loading = false
          this.addCar = false
          this.isError(err,this)
        })
      },
      cleanDialogAdd(){//关闭清楚添加车辆框
        this.recoverImg()
        this.pic = []
        this.pic2 = []
        this.pic3 = []
        this.hideUpload = this.pic.length >= this.limitCount;
        this.hideUpload2 = this.pic2.length >= this.limitCount2;
        this.hideUpload3 = this.pic3.length >= this.limitCount3;
        this.resetForm(["roomForm"],this)
        this.addVehicle = {
          svrVehicleUse: '',//车辆用途
          svrVehicleType: '',//车辆类型
          svrAnnualReview: '',//下次年审
          svrVehicleNumber: '',//车牌号码
          svrTotalMileage: '',//总里程
          svrMaintain: '',//下次保养
          svrCatalog: '',//使用性质
          svrFrameNumber: '',//车架号
          svrRegistrationTime: '',//注册时间
          svrBrandModel: '',//品牌型号
          svrNuclearLoading: '',//核载人数
          userCoding: '',//负责人
          svrRegisteredAddress: '',//注册地址
          svrEngineNumber: '',//发动机号
          svrInsuranceCompany: '',//保险公司
          svrPolicyNumber: '',//保单号
          svrInsurancePeriod: '',//车险到期
          tableTypes: ['车辆照片','行驶证','保险单'],//外表类型
          deleteImgList: [],//删除图片列表
        }
      },
      vehicleNumber(){//车辆号码查询
        this.$axios({
          method: 'get',
          url: `/common1/vehicleRegistrations`,
          params: {
            limit: 10000000,
          }
        }).then(res => {
          this.vehicleNumberData = res.data.result.records
        }).catch(err => {
          this.isError(err,this)
        })
      },
      carHandOverBuuton(){//车辆移交
        if(Object.keys(this.tableRow).length == 0){
          this.$message.warning('请先选择一条记录')
          return
        }
        if(this.tableRow.svrCurrentState != '空置'){
          this.$message.warning(`车辆正在${this.tableRow.svrCurrentState}`)
          return
        }
        this.maintenanceData.randomNum = parseInt((Math.random() * 9 + 1) * 100000000000)
        this.vehicleNumber()
        this.carHandOver = true
      },
      cleanDialogHandOver(){//车辆移交清空
        this.carHandOverData = {
          vehicle: '',//车辆号
          mileage: '',//移交里程
          newHead: '',//新负责人
          transferReason: '',//原因
          documentsHand: '',//证件移交
        }
        this.maintenanceData.svmLiftCarOil = ''
        this.maintenanceData.svmLiftCarBattery = ''
        this.maintenanceData.svmLiftCarMileage = ''
        this.maintenanceData.randomNum = ''
        this.maintenanceData.tableTypes = ['提车外观']
      },
      vehicleChe(){//根据车辆号查询油量电量里程
        this.$axios({
          method: 'get',
          url: `/common1/vehicleRegistrations/${this.carHandOverData.vehicle}`,
        }).then(res => {
          let result = res.data.result
          this.maintenanceData.svmLiftCarOil = result.svrCarOil
          this.maintenanceData.svmLiftCarBattery = result.svrCarBattery
          this.maintenanceData.svmLiftCarMileage = result.svrTotalMileage
        }).catch(err => {
          this.isError(err,this)
        })
      },
      carHandOverSave(){//移交车辆保存
        this.maintenanceData.svmVehicleType = '移交'
        this.$axios({
          method: 'post',
          url: `/common1/vehicleManagements`,
          data: {
            svmVehicleId: this.carHandOverData.vehicle,
            svmLiftCarMan: Number(this.carHandOverData.newHead),
            svmReasons: this.carHandOverData.transferReason,
            svmTransferCertificates: this.carHandOverData.documentsHand,
            svmLiftCarOil: this.maintenanceData.svmLiftCarOil,
            svmLiftCarBattery: this.maintenanceData.svmLiftCarBattery,
            svmLiftCarMileage: this.maintenanceData.svmLiftCarMileage,
            randomNum: this.maintenanceData.randomNum,
            tableTypes: this.maintenanceData.tableTypes,
            svmVehicleType: this.maintenanceData.svmVehicleType
          }
        }).then(res => {
          this.$message.success('移交成功')
          this.carHandOver = false
          this.vehicleRecord()
        }).catch(err => {
          this.isError(err,this)
        })
      },
      maintenanceCarButton(){
        if(Object.keys(this.tableRow).length == 0){
          this.$message.warning('请先选择一条记录')
          return
        }
        if(this.tableRow.svrCurrentState != '空置'){
          this.$message.warning(`车辆正在${this.tableRow.svrCurrentState}`)
          return
        }
        this.maintenanceData.randomNum = parseInt((Math.random() * 9 + 1) * 100000000000)
        this.maintenanceCar = true
        this.vehicleNumber()
      },
      maintenanceCarSave(){//车辆保养保存
        this.maintenanceData.svmMaintenanceCost = Number(this.maintenanceData.svmMaintenanceCost)
        this.maintenanceData.svmLiftCarMan = Number(this.maintenanceData.svmLiftCarMan)
        this.maintenanceData.svmVehicleId = Number(this.carHandOverData.vehicle)
        this.$axios({
          method: 'post',
          url: `/common1/vehicleManagements`,
          data: this.maintenanceData
        }).then(res => {
          this.$message.success('保存成功')
          this.maintenanceCar = false
          this.vehicleRecord()
        }).catch(err => {
          this.isError(err,this)
        })
      },
      //交车照片相关
      // async beforeUploadIncar(file){//上传完文件前获取token
      //   try {
      //     let getToken = await this.getToken();
      //     this.picIncarData.token = getToken;
      //     if(file.type == "jpeg"){
      //       return false
      //     }else{
      //       this.picIncarData["x:photoMimeType"] = "图片"
      //     }
      //     this.picIncarData["x:filename"] = file.name;
      //     this.picIncarData["x:co"] = sessionStorage.getItem("co");
      //     this.picIncarData["x:photoTableType"] = "交车外观";
      //     this.picIncarData["x:photoBucket"] = "私有";
      //     this.picIncarData["x:photoTableId"] = '';
      //     this.picIncarData["x:photoRandom"] = this.maintenanceData.randomNum;
      //   } catch (err) {
      //     console.log(err);
      //   }
      // },
      // handleAvatarSuccessIncar(res, file,fileList) {//上传成功后在图片框显示图片
      //   this.picIncar = fileList;
      //   this.hideUpload4 = fileList.length >= this.limitCount4;
      // },
      beforeRemoveIncar(file) {//删除前
        return this.$confirm(`确定移除该文件？`).then(() => {
          this.$axios({
            url: `/common1/photos`,
            method: "DELETE",
            params: {
              bucket: "私有",
              photoIds: file.response.result
            }
          }).then(res => {
            console.log(res);
          }).catch(err => {
            this.isError(err,this)
          });
        });
      },
      // handleRemoveIncar(file,fileList){//删除后
      //   this.picIncar=fileList
      //   this.hideUpload4 = fileList.length >= this.limitCount4;
      // },
      //提车照片相关
      async beforeUploadOutcar(file){//上传完文件前获取token
        try {
          let getToken = await this.getToken();
          this.picOutcarData.token = getToken;
          if(file.type == "jpeg"){
            return false
          }else{
            this.picOutcarData["x:photoMimeType"] = "图片"
          }
          this.picOutcarData["x:filename"] = file.name;
          this.picOutcarData["x:co"] = sessionStorage.getItem("co");
          this.picOutcarData["x:photoTableType"] = "提车外观";
          this.picOutcarData["x:photoBucket"] = "私有";
          this.picOutcarData["x:photoTableId"] = '';
          this.picOutcarData["x:photoRandom"] = this.maintenanceData.randomNum;
        } catch (err) {
          console.log(err);
        }
      },
      handleAvatarSuccessOutcar(res, file,fileList) {//上传成功后在图片框显示图片
        this.picOutcar = fileList;
        this.hideUpload5 = fileList.length >= this.limitCount5;
      },
      handleRemoveOutcar(file,fileList){//删除后
        this.picOutcar=fileList
        this.hideUpload5 = fileList.length >= this.limitCount5;
      },
      cleanDialogMaintenance(){//车辆保养清空
        this.picIncar = []
        this.picOutcar = []
        this.hideUpload4 = this.picIncar.length >= this.limitCount4;
        this.hideUpload5 = this.picOutcar.length >= this.limitCount5;
        this.maintenanceData = {
          svmMaintenanceProgram: '',
          svmMaintenanceCost: '',
          svmOldDate: '',
          svmReasons: '',
          svmOldPersonCharge: '',
          svmCompany: '',
          svmLiftCarMan: '',
          svmOldFuel: '',
          svmOldElectrics: '',
          svmOldMileage: '',
          svmLiftCarOil: '',
          svmLiftCarBattery: '',
          svmLiftCarMileage: '',
          randomNum: '',
          tableTypes: ['提车外观'],
          svmVehicleType: '保养'
        }
      },
      yearCarefulButton(){//车辆年审
        if(Object.keys(this.tableRow).length == 0){
          this.$message.warning('请先选择一条记录')
          return
        }
        if(this.tableRow.svrCurrentState != '空置'){
          this.$message.warning(`车辆正在${this.tableRow.svrCurrentState}`)
          return
        }
        this.maintenanceData.randomNum = parseInt((Math.random() * 9 + 1) * 100000000000)
        this.yearCareful = true
        this.vehicleNumber()
      },
      yearCarefulSave(){
        this.yearCarefulData.approver = Number(this.yearCarefulData.approver)
        this.$axios({
          method: 'post',
          url: `/common1/vehicleManagements`,
          data: {
            svmVehicleId: this.carHandOverData.vehicle,
            svmTheDate: this.yearCarefulData.nextMileage,
            svmNextDate: this.yearCarefulData.nextTime,
            svmCompany: this.yearCarefulData.carUnit,
            svmLiftCarMan: this.yearCarefulData.approver,
            randomNum: this.maintenanceData.randomNum,
            tableTypes: this.maintenanceData.tableTypes,
            svmVehicleType: '年审'
          }
        }).then(res => {
          this.$message.success('提交成功')
          this.yearCareful = false
          this.vehicleRecord()
        }).catch(err => {
          this.isError(err,this)
        })
      },
      cleanDialogCareful(){//车辆年审清空
        this.yearCarefulData = {
          vehicle: '',
          nextMileage: '',
          nextTime: '',
          carUnit: '',
          approver: '',
        }
        this.maintenanceData.randomNum = ''
        this.maintenanceData.tableTypes = ['提车外观']
      },
      handleCurrentChange(cursor) {
        this.current = cursor
        this.vehicleRecord()
      },
      handleSizeChange(limit){
        this.size = limit
        this.vehicleRecord()
      },
      tableClick(row){
        this.tableRow = row
      },
      doubleClick(){//表格双击
        this.$axios({
          method: 'get',
          url: `/common1/vehicleRegistrations/${this.row.svrId}`
        }).then(res => {
          for(let i in res.data.result){
            for(let j in this.addVehicle){
              if(i === j){
                this.addVehicle[j] = res.data.result[i]
              }
            }
          }
          if(res.data.result.userCoding){
            this.addVehicle.userCoding = res.data.result.userCoding.toString()
          }
          this.getPhoto()
          this.getPhotoLicense()
          this.getPhotoPolicy()
          this.hideUpload6 = true
          this.dialogVehicleDetails = true
        }).catch(err => {
          this.isError(err,this)
        })
      },
      historyGet(){
        this.$axios({
          method: 'get',
          url: `/common1/vehicleManagements`,
          params: {
            svrId: this.row.svrId
          }
        }).then(res => {
          this.historyData = res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      tabChange(){
        if(this.tab == 'second'){
          this.historyGet()
        }
      },
      doubleClickHistory(){
        this.$axios({
          method: 'get',
          url: `/common1/vehicleManagements/${this.rowHistory.svmId}`,
        }).then(res => {
          console.log(res);
          this.historyXqObj = res.data.result
          this.dialogHistory = true
        }).catch(err => {
          this.isError(err,this)
        })
      },
      currentChangeHistory(row){
        this.rowHistory = row
      },
      vehicleManagements(id){//查询单个年审保养移交记录
        this.$axios({
          method: 'get',
          url: `/common1/vehicleManagements/${id}`
        }).then(res => {
          this.returnDetails = res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      returnCar(row){//还车
        this.returnState = row.svrCurrentState
        this.vehicleManagements(row.svrSvmId)
        this.returnCarDialog = true
      },
      returnCarPut(){//还车提交
        this.alsoRemaining.randomNum = parseInt((Math.random() * 9 + 1) * 10000000)
        this.alsoRemaining.svmVehicleId = this.returnDetails.svmVehicleId

        switch(this.returnDetails.svmVehicleType){
          case '移交':
            this.alsoRemaining.svmVehicleType = '移交'
            break;
          case '年审':
            this.alsoRemaining.svmVehicleType = '年审'
            break;
          case '保养':
            this.alsoRemaining.svmVehicleType = '保养'
            break;
        }
        this.$axios({
          method: 'put',
          url: `/common1/vehicleManagements/${this.returnDetails.svmId}`,
          data: this.alsoRemaining
        }).then(res => {
          this.$message.success('归还成功')
          this.vehicleRecord()
          this.returnCarDialog = false
        }).catch(err => {
          this.isError(err,this)
        })
      },
      cleanDialogReturn(){//归还移交车辆清空
        for(let i in this.alsoRemaining){
          if(typeof this.alsoRemaining[i] == 'object'){
            continue
          }
          this.alsoRemaining[i] = ''
        }
      },
      cleanDialogDetails(){
        this.pic = []
        this.pic2 = []
        this.pic3 = []
        this.tab = 'first'
        this.addVehicle = {
          svrCarOil: '',//车油量
          svrCarBattery: '',//车电量
          suStaffName: '',
          svrVehicleUse: '',//车辆用途
          svrVehicleType: '',//车辆类型
          svrAnnualReview: '',//下次年审
          svrVehicleNumber: '',//车牌号码
          svrTotalMileage: '',//总里程
          svrMaintain: '',//下次保养
          svrCatalog: '',//使用性质
          svrFrameNumber: '',//车架号
          svrRegistrationTime: '',//注册时间
          svrBrandModel: '',//品牌型号
          svrNuclearLoading: '',//核载人数
          userCoding: '',//负责人
          svrRegisteredAddress: '',//注册地址
          svrEngineNumber: '',//发动机号
          svrInsuranceCompany: '',//保险公司
          svrPolicyNumber: '',//保单号
          svrInsurancePeriod: '',//车险到期
          tableTypes: ['车辆照片','行驶证','保单照片'],//外表类型
          randomNum: '',//随机数
          deleteImgList: [],//删除图片列表
        }
      },
      currentChange(row){
        this.row = row
        console.log(this.row)
      }
    },
    computed:{
      ...mapState(['authe','pageAuth'])
    }
  }
</script>
<style lang="scss" scoped>
  >>> .el-upload--picture-card{
    width: 180px;
  }
  >>> .ql-editor{
    height: 150px;
  }
  .hide .el-upload--picture-card,
  /deep/ .hide2 .el-upload--picture-card,
  /deep/ .hide3 .el-upload--picture-card,
  /deep/ .hide4 .el-upload--picture-card,
  /deep/ .hide5 .el-upload--picture-card,
  /deep/ .hide6 .el-upload--picture-card{
    display: none;
  }
</style>
